import { BrowserRouter } from "react-router-dom"
import Routers from "./router"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return(
    <BrowserRouter>
      <Routers />
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App;