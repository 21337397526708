import { useState } from "react";
import "./styles.css"
import axios from "axios";
import { toast } from "react-toastify";

import Loading from "../../components/loading"
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {

    const navigate = useNavigate();

    const [username, setusername] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const [showPass, setShowPass] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post('https://common.integracaopixhotline.com.br/inscr/revenda/login', {
            username,
            password
        })
        .then(() => {
            toast("Inscrição realizada com successo!", { type: 'success' })
            setTimeout(() => {
                navigate('/')
            }, 2500)
        })
        .catch((err) => {
            if(err?.response?.data?.detail === "Invalid credentials"){
                toast("Credenciais inválidas", { type: 'error' })
                return
            }
            toast("Error ao realizar Inscrição", { type: 'error' })
        })
        .finally(() => setLoading(false))
    }

    return (
        <div>
            <section className="min-vh-100 d-flex align-items-center section-image overlay-soft-dark">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h1 className="mb-0 h3">Inscrição da integração PIX</h1>
                                    <h4 className="sibtile-px-register">Use suas credenciais que loga no site da Hotline, para migrar todas as informações necessárias para o serviço pix.</h4>
                                </div>
                                <form onSubmit={handleSubmit} className="mt-4">
                                    <div className="form-group mb-4">
                                        <label for="email">Seu Email</label>
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <span class="material-symbols-outlined">
                                                    mail
                                                </span>
                                            </span>
                                            <input 
                                            value={username}
                                            onChange={e => setusername(e.target.value)}
                                            type="email" className="form-control" placeholder="examplo@email.com" id="email" required />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group mb-4">
                                            <label for="password">Sua senha</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon2">
                                                    <span class="material-symbols-outlined">
                                                        lock
                                                    </span>
                                                </span>
                                                <input 
                                                value={password}

                                                onChange={e => setPassword(e.target.value)}
                                                type={showPass ? "text" : "password"} placeholder="senha" className="form-control" id="password" required />
                                                 <span className="input-group-text" id="basic-addon2">
                                                    <span onClick={() => setShowPass(!showPass)} className="material-symbols-outlined pointer">
                                                        {!showPass ? "visibility" : "visibility_off"}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button 
                                        type="submit" 
                                        disabled={loading} 
                                        className={"btn " + (loading ? "btn-primary-app" : "btn-primary")}>
                                            {loading ? <Loading /> : "Inscrever-se"}
                                        </button>
                                    </div>
                                </form>
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <span className="fw-normal">
                                        Não é revenda?
                                        <a href="https://www.hotlinetecnologia.com.br" target="_blank" className="fw-bold text-underline">
                                           {'  '} Conheça o sistema hotline
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RegisterPage;