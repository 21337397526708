import { Navigate, Outlet, useRoutes } from "react-router-dom"
import RegisterPage from "../pages/register"
import HomePage from "../pages/home"
const Routers = () => {
    return useRoutes([
        {
            path: '/',
            
            element:  <Outlet />,
            children: [
                {
                    path: '/*',
                    element: <Navigate to="/"/>
                },
                {
                    path: '/',
                    element: <HomePage/>
                },
                {
                    path: '/inscricao',
                    element: <RegisterPage/>
                }
            ]
        }
    ])
}

export default Routers;