import React from 'react'
import '../App.css'

function HomePage() {
  const [toggled, setToggled] = React.useState(false)
  const [fixed, setFixed] = React.useState(false)

  React.useEffect(() => {
    function handleScroll() {
      setFixed(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  React.useEffect(() => {
    const d = document
    const breakpoints = {
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140
    }

    var dropdownElementList = [].slice.call(document.querySelectorAll('.navbar .dropdown-toggle'))

    if (d.body.clientWidth > breakpoints.lg) {
      dropdownElementList.map(function (dropdownToggleEl) {
        var dropdownMenu = d.querySelector('.dropdown-menu[aria-labelledby="' + dropdownToggleEl.getAttribute('id') + '"]')
        dropdownToggleEl.addEventListener("click", () => console.log("opa"))

        dropdownToggleEl.addEventListener('mouseover', function () {
          dropdownMenu.classList.add("show")
        })

        dropdownToggleEl.addEventListener('mouseout', function () {
          dropdownMenu.classList.remove("show")
        })

        dropdownMenu.addEventListener('mouseover', function () {
          dropdownMenu.classList.add("show")
        })

        dropdownMenu.addEventListener('mouseout', function () {
          dropdownMenu.classList.remove("show")
        })
      })
    } else {
      dropdownElementList.map(function (dropdownToggleEl) {
        var dropdownMenu = document.querySelector('.dropdown-menu[aria-labelledby="' + dropdownToggleEl.getAttribute('id') + '"]')
        dropdownToggleEl.addEventListener("click", () => {
          dropdownMenu.classList.toggle("show")
        })
      })
    }
  }, [])

  return (
    <>
      <header className="header-global">
        <nav id="navbar-main" aria-label="Primary navigation" className={`navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-dark navbar-theme-secondary ${fixed ? "headroom--not-bottom headroom--not-top headroom--pinned" : ""}`}>
          <div className="container position-relative">
            <a className="navbar-brand me-lg-5" href="#">
              {/* <img className="navbar-brand-dark" src="/img/brand/light.svg" alt="Logo light" />
              <img className="navbar-brand-light" src="/img/brand/dark.svg" alt="Logo dark" /> */}
              {/* <h4 style={{ color: "#f3f3f3" }}>Logo</h4> */}
              <img className="navbar-brand-dark" src="/img/HOTLINE.png" alt="Logo" />
            </a>
            <div className={`navbar-collapse collapse me-auto ${toggled ? "show" : ""}`} id="navbar_global">
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <a href="#">
                      <img src="/img/brand/dark.svg" alt="Themesberg logo" />
                    </a>
                  </div>
                  <div onClick={() => setToggled(false)} className="col-6 collapse-close">
                    <a href="#navbar_global" className="fa fa-times" data-bs-toggle="collapse" data-bs-target="#navbar_global" aria-controls="navbar_global" title="close" aria-label="Toggle navigation"></a>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle" id="frontPagesDropdown" data-bs-toggle="dropdown">
                    Pages
                    <span className="fa fa-angle-down nav-link-arrow ms-1"></span>
                  </a>
                  <div className="dropdown-menu dropdown-megamenu px-0 py-2 p-lg-4" aria-labelledby="frontPagesDropdown">
                    <div className="row">
                      <div className="col-6 col-lg-4">
                        <h6 className="d-block mb-3 text-primary">Main pages</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="../../html/pages/register/index.html">About</a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="../../html/pages/contact.html">Contact</a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/pricing.html" target="_blank">Pricing <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/team.html" target="_blank">Team <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/services.html" target="_blank">Services <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/profile.html" target="_blank">Profile <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                        <h6 className="d-block text-primary">Legal</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/legal.html" target="_blank">Legal center <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/terms.html" target="_blank">Terms <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                        <h6 className="d-block text-primary">Career</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/careers.html" target="_blank">Careers <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/career-single.html" target="_blank">Career Single <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-6 col-lg-4">
                        <h6 className="d-block mb-3 text-primary">Landings</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="../../html/pages/landing-freelancer.html">Freelancer</a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/landing-app.html" target="_blank">App <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/landing-crypto.html" target="_blank">Crypto <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                        <h6 className="d-block mb-3 text-primary">Support</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/support.html" target="_blank">Support center <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/support-topic.html" target="_blank">Support topic <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                        <h6 className="d-block mb-3 text-primary">Blog</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/blog.html" target="_blank">Blog <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/blog-post.html" target="_blank">Blog post <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-6 col-lg-4">
                        <h6 className="d-block mb-3 text-primary">User</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="../../html/pages/sign-in.html">Sign in</a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="../../html/pages/sign-up.html">Sign up</a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/forgot-password.html" target="_blank">Forgot password <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/reset-password.html" target="_blank">Reset password <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                        <h6 className="d-block mb-3 text-primary">Special</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/404.html" target="_blank">404 Not Found <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/500.html" target="_blank">500 Server Error <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/maintenance.html" target="_blank">Maintenance <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/pages/coming-soon.html" target="_blank">Coming soon <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="../../html/pages/blank.html">Blank page</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle" id="dashboardDropdown" data-bs-toggle="dropdown">
                    Dashboard
                    <span className="fa fa-angle-down nav-link-arrow ms-1"></span>
                  </a>
                  <div className="dropdown-menu dropdown-megamenu-sm px-0 py-2 p-lg-4" aria-labelledby="dashboardDropdown">
                    <div className="row">
                      <div className="col-6">
                        <h6 className="d-block mb-3 text-primary">User dashboard</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/account.html" target="_blank">My account <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/settings.html" target="_blank">Settings <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/security.html" target="_blank">Security <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                        <h6 className="d-block mb-3 text-primary">Items</h6>
                        <ul className="list-style-none">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/my-items.html" target="_blank">My items <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/edit-item.html" target="_blank">Edit item <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <h6 className="d-block mb-3 text-primary">Messaging</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/messages.html" target="_blank">Messages <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/single-message.html" target="_blank">Chat <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                        <h6 className="d-block mb-3 text-primary">Billing</h6>
                        <ul className="list-style-none mb-4">
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/billing.html" target="_blank">Billing details <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                          <li className="mb-2 megamenu-item">
                            <a className="megamenu-link" href="https://demo.themesberg.com/pixel-pro/v5/html/dashboard/invoice.html" target="_blank">Invoice <span className="badge bg-tertiary">Pro</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle" id="componentsDropdown" data-bs-toggle="dropdown">
                    Components
                    <span className="fa fa-angle-down nav-link-arrow ms-1"></span>
                  </a>
                  <div className="dropdown-menu dropdown-megamenu-md p-0" aria-labelledby="componentsDropdown">
                    <div className="row g-0">
                      <div className="col-lg-6 bg-dark d-none d-lg-block me-0 me-3">
                        <div className="px-0 py-3 text-center">
                          <img src="/img/megamenu_image.png" alt="Pixel Components" />
                        </div>
                      </div>
                      <div className="col ps-0 py-3">
                        <ul className="list-style-none">
                          <li><a className="dropdown-item" href="../../html/components/accordions.html">Accordions</a></li>
                          <li><a className="dropdown-item" href="../../html/components/alerts.html">Alerts</a></li>
                          <li><a className="dropdown-item" href="../../html/components/badges.html">Badges</a></li>
                          <li><a className="dropdown-item" href="../../html/components/cards.html">Cards</a></li>
                          <li><a className="dropdown-item" href="https://demo.themesberg.com/pixel-pro/v5/html/components/charts.html" target="_blank">Charts <span className="badge bg-tertiary">Pro</span></a></li>
                          <li><a className="dropdown-item" href="../../html/components/bootstrap-carousels.html">Carousels</a></li>
                          <li><a className="dropdown-item" href="../../html/components/breadcrumbs.html">Breadcrumbs</a></li>
                          <li><a className="dropdown-item" href="../../html/components/buttons.html">Buttons</a></li>
                          <li><a className="dropdown-item" href="https://demo.themesberg.com/pixel-pro/v5/html/components/counters.html" target="_blank">Counters <span className="badge bg-tertiary">Pro</span></a></li>
                        </ul>
                      </div>
                      <div className="col ps-0 py-3">
                        <ul className="list-style-none">
                          <li><a className="dropdown-item" href="../../html/components/dropdowns.html">Dropdowns</a></li>
                          <li><a className="dropdown-item" href="https://demo.themesberg.com/pixel-pro/v5/html/components/e-commerce.html" target="_blank">E-commerce <span className="badge bg-tertiary">Pro</span></a></li>
                          <li><a className="dropdown-item" href="../../html/components/forms.html">Forms</a></li>
                          <li><a className="dropdown-item" href="https://demo.themesberg.com/pixel-pro/v5/html/components/icon-boxes.html" target="_blank">Icon Boxes <span className="badge bg-tertiary">Pro</span></a></li>
                          <li><a className="dropdown-item" href="../../html/components/modals.html">Modals</a></li>
                          <li><a className="dropdown-item" href="../../html/components/navs.html">Navs</a></li>
                          <li><a className="dropdown-item" href="https://demo.themesberg.com/pixel-pro/v5/html/components/glidejs-carousels.html" target="_blank">GlideJS <span className="badge bg-tertiary">Pro</span></a></li>
                          <li><a className="dropdown-item" href="../../html/components/pagination.html">Pagination</a></li>
                          <li><a className="dropdown-item" href="../../html/components/popovers.html">Popovers</a></li>
                        </ul>
                      </div>
                      <div className="col ps-0 py-3">
                        <ul className="list-style-none">
                          <li><a className="dropdown-item" href="../../html/components/progress-bars.html">Progress Bars</a></li>
                          <li><a className="dropdown-item" href="https://demo.themesberg.com/pixel-pro/v5/html/components/steps.html" target="_blank">Steps <span className="badge bg-tertiary">Pro</span></a></li>
                          <li><a className="dropdown-item" href="../../html/components/tables.html">Tables</a></li>
                          <li><a className="dropdown-item" href="../../html/components/tabs.html">Tabs</a> </li>
                          <li><a className="dropdown-item" href="../../html/components/toasts.html">Toasts</a> </li>
                          <li><a className="dropdown-item" href="https://demo.themesberg.com/pixel-pro/v5/html/components/timelines.html" target="_blank">Timelines <span className="badge bg-tertiary">Pro</span></a></li>
                          <li><a className="dropdown-item" href="../../html/components/tooltips.html">Tooltips</a></li>
                          <li><a className="dropdown-item" href="../../html/components/typography.html">Typography</a></li>
                          <li><a className="dropdown-item" href="https://demo.themesberg.com/pixel-pro/v5/html/components/widgets.html" target="_blank">Widgets <span className="badge bg-tertiary">Pro</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" id="supportDropdown">
                    Support
                    <span className="fa fa-angle-down nav-link-arrow ms-1"></span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-lg" aria-labelledby="supportDropdown">
                    <div className="col-auto px-0">
                      <div className="list-group list-group-flush">
                        <a href="https://themesberg.com/docs/bootstrap-5/pixel/getting-started/quick-start/" target="_blank" className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">
                          <span className="icon icon-sm"><span className="fa fa-file-alt"></span></span>
                          <div className="ms-4">
                            <span className="d-block font-small fw-bold mb-0">Documentation<span className="badge badge-sm badge-secondary ms-2">v3.1</span></span>
                            <span className="small">Examples and guides</span>
                          </div>
                        </a>
                        <a href="https://github.com/themesberg/pixel-bootstrap-ui-kit/issues" target="_blank" className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">
                          <span className="icon icon-sm"><span className="fa fa-microphone-alt"></span></span>
                          <div className="ms-4">
                            <span className="d-block font-small fw-bold mb-0">Support</span>
                            <span className="small">Need help? Ask us!</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="d-flex align-items-center">
              <a href="http://dashboard.integracaopixhotline.com.br" className="btn btn-outline-gray-100 d-none d-lg-inline me-md-3"><span className="fa fa-lock me-2"></span>Restrito</a>
              <a href="http://cpanel.integracaopixhotline.com.br" className="btn btn-tertiary"><i className="fa fa-sign-in me-2"></i> Entrar</a>
              {/* <a href="https://frontadmin.forcavendahotline.com.br/" className="btn btn-tertiary d-none d-lg-inline me-md-3"> Mercado Pago</a> */}
              {/* <a href="https://frontrevenda.forcavendahotline.com.br/" className="btn btn-tertiary d-none d-lg-inline me-md-3">Outros Bancos</a> */}
              <button onClick={() => setToggled(true)} className="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbar_global" aria-controls="navbar_global" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <section className="section-header bg-secondary text-white">
          {/* <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-7 col-lg-6 text-center text-md-left">
                <h1 className="display-2 mb-4">Full-Service <br className="d-none d-md-inline" />Digital Agency</h1>
                <p className="lead mb-4 text-muted">Themesberg can help you build a modern website, a creative logo or PWA, that will bring you customers and and stay on top of your competition.</p>
                <a href="./services.html" className="btn btn-tertiary me-3 animate-up-2">What we do <span className="ms-2"><span className="fa fa-arrow-right"></span></span></a>
              </div>
              <div className="col-12 col-md-5 d-none d-md-block text-center position-absolute end-0 top-5 bottom-0"><img src="/img/person.png" alt="" /></div>
            </div>
          </div> */}

          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="content col-12 col-md-7 col-lg-6 text-md-left">
                <h1 className="display-4 mb-4">
                  Plataforma de gestão de pagamento Pix
                </h1>
                <h4 className="font-weight-light">Venda mais com o pix.</h4>
                <p className="lead mb-4 text-muted">

                  Use em sua empresa.
                </p>

                <a href="/inscricao"
                  className="btn btn-tertiary me-3 animate-up-2 w-100">
                  Faça sua inscrição agora
                  <span className="ms-2"><span className="fa fa-arrow-right"></span></span>
                </a>
              </div>
              <div className="col-12 col-md-5" id="img-person"></div>
            </div>
          </div>
        </section>
        <section className="section section-md">
          <div className="container">
            <div className="row align-items-center justify-content-around">
              <div className="col-md-6 col-xl-6 mb-5">
                <img className="img-fluid" src="/img/notebook-mp-2.png" alt="Office Desk" />
              </div>
              <div className="col-md-6 col-xl-4 text-center text-md-left">
                <h2 className="h1 mb-5">Aumente sua produtividade<br />
                  e a qualidade dos seus<br />
                  atendimentos.</h2>
                <p className="lead">Pagamento, fácil, rápido e
                  totalmente seguro.</p>
                {/* <p className="lead">With a culture of collaboration, a roster of talent, and several office pooches, the Themesberg team is active in the creative community, endlessly interested in what’s next, and generally pleasant to be around.</p>
                <img src="/img/signature.svg" alt="signature" className="mt-4" width="150" /> */}
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section section-lg pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-4 text-center">
                <div className="icon-box mb-4">
                  <div className="icon icon-primary mb-4">
                    <span className="fa fa-user"></span>
                  </div>
                  <h3 className="h5">Membros do time</h3>
                  <span className="counter display-3 text-gray d-block">500</span>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 text-center">
                <div className="icon-box mb-4">
                  <div className="icon icon-primary mb-4">
                    <span className="fa fa-money"></span>
                  </div>
                  <h3 className="h5">Projetos Publicados</h3>
                  <span className="counter display-3 text-gray d-block">2400</span>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 text-center">
                <div className="icon-box mb-4">
                  <div className="icon icon-primary mb-4">
                    <span className="fa fa-globe"></span>
                  </div>
                  <h3 className="h5">Países</h3>
                  <span className="counter display-3 text-gray d-block">80</span>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="section section-lg bg-gray-200 mb-8">
          <figure className="position-absolute top-0 left-0 w-100 d-none d-md-block mt-n3">
            <svg className="fill-gray-200" x="0px" y="0px" viewBox="0 0 1920 43.4"  >
              <path d="M0,23.3c0,0,405.1-43.5,697.6,0c316.5,1.5,108.9-2.6,480.4-14.1c0,0,139-12.2,458.7,14.3 c0,0,67.8,19.2,283.3-22.7v35.1H0V23.3z"></path>
            </svg>
          </figure>
          <div className="container">
            <div className="row text-center">
              <div className="col">
                <h2 className="h1 fw-light">Vantagens do <span className="fw-bold">integração Pix
                  Mercado Pago</span></h2>
              </div>
            </div>
            <div className="row justify-content-center mt-6">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-8">
                  <div className="timeline timeline-one dark px-3 px-sm-0">
                    <div className="timeline-item">
                      <h3 className="h4 mb-4">Texto</h3>
                      <p>Now over seven years old, Bootstrap is an established and widely-used HTML, CSS, and JavaScript framework. It can be used as a basis for creating responsive web and mobile sites and web applications.</p>
                    </div>
                    <div className="timeline-item">
                      <h3 className="h4 mb-4">Texto</h3>
                      <div className="my-3">
                        <span className="icon icon-sm icon-purple me-2"><span className="fab fa-bootstrap"></span></span>
                        <span className="icon icon-sm icon-info me-2"><span className="fab fa-react"></span></span>
                        <span className="icon icon-sm icon-success me-2"><span className="fab fa-vuejs"></span></span>
                        <span className="icon icon-sm icon-danger"><span className="fab fa-angular"></span></span>
                      </div>
                      <p>Bootstrap. Build responsive, mobile-first projects on the web with the world's most popular front-end component library. Bootstrap is an open source toolkit for developing with HTML, CSS, and JS. Quickly prototype your
                        ideas.
                      </p>
                    </div>
                    <div className="timeline-item">
                      <h3 className="h4 mb-4">Texto</h3>
                      <img className="mt-2" src="/img/office.png" alt="Themesberg workspace" width="300" />
                      <p>AngularJS is a JavaScript-based open-source front-end web application framework mainly maintained by Google and by a community of individuals and corporations to address many of the challenges encountered in developing
                        single-page applications.
                      </p>
                    </div>
                    <div className="timeline-item">
                      <h3 className="h4 mb-4">Texto</h3>
                      <p>AngularJS is a JavaScript-based open-source front-end web application framework mainly maintained by Google and by a community of individuals and corporations to address many of the challenges encountered in developing
                        single-page applications.
                      </p>
                      <img className="mt-2" src="/img/signature.svg" alt="signature" width="100" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg className="fill-white" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 43.4" enableBackground={"new 0 0 1920 43.4"}>
              <path d="M0,23.3c0,0,405.1-43.5,697.6,0c316.5,1.5,108.9-2.6,480.4-14.1c0,0,139-12.2,458.7,14.3 c0,0,67.8,19.2,283.3-22.7v35.1H0V23.3z"></path>
            </svg>
          </figure>
        </section>
        {/* <section className="section section-lg">
          <div className="container">
            <div className="row mb-5 mb-lg-6">
              <div className="col-12 col-md-9 col-lg-8 text-center mx-auto">
                <h2 className="h1 mb-4">FeedBacks</h2>
                <p className="lead">
                  O segredo de um excelente trabalho, é o prazer
                  de ver você sa sfeito com o nosso trabalho.
                </p>
              </div>
            </div>
            <div className="row mb-5 mb-lg-6">
              <div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div className="card shadow border-gray-300">
                  <img src="/img/team/profile-picture-1.jpg" className="card-img-top rounded-top" alt="Joseph Portrait" />
                  <div className="card-body">
                    <h3 className="h4 card-title mb-2">Joseph Garth</h3>
                    <span className="card-subtitle text-gray fw-normal">Co-Founder</span>
                    <p className="card-text my-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    {/* <ul className="list-unstyled d-flex mt-3 mb-0">
                      <li>
                        <a href="#" target="_blank" aria-label="facebook social link" className="icon-facebook me-3">
                          <span className="fab fa-facebook-f"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="twitter social link" className="icon-twitter me-3">
                          <span className="fab fa-twitter"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="slack social link" className="icon-slack me-3">
                          <span className="fab fa-slack-hash"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="dribbble social link" className="icon-dribbble me-3">
                          <span className="fab fa-dribbble"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div className="card shadow border-gray-300">
                  <img src="/img/team/profile-picture-3.jpg" className="card-img-top rounded-top" alt="Bonnie portrait" />
                  <div className="card-body">
                    <h3 className="h4 card-title mb-2">Bonnie Green</h3>
                    <span className="card-subtitle text-gray fw-normal">Web Developer</span>
                    <p className="card-text my-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <ul className="list-unstyled d-flex mt-3 mb-0">
                      <li>
                        <a href="#" target="_blank" aria-label="facebook social link" className="icon-facebook me-3">
                          <span className="fab fa-facebook-f"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="twitter social link" className="icon-twitter me-3">
                          <span className="fab fa-twitter"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="slack social link" className="icon-slack me-3">
                          <span className="fab fa-slack-hash"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="dribbble social link" className="icon-dribbble me-3">
                          <span className="fab fa-dribbble"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card shadow border-gray-300">
                  <img src="/img/team/profile-picture-4.jpg" className="card-img-top rounded-top" alt="Jose Avatar" />
                  <div className="card-body">
                    <h3 className="h4 card-title mb-2">Jose Leos</h3>
                    <span className="card-subtitle text-gray fw-normal">Web publications designer</span>
                    <p className="card-text my-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    {/* <ul className="list-unstyled d-flex mt-3 mb-0">
                      <li>
                        <a href="#" target="_blank" aria-label="facebook social link" className="icon-facebook me-3">
                          <span className="fab fa-facebook-f"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="twitter social link" className="icon-twitter me-3">
                          <span className="fab fa-twitter"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="slack social link" className="icon-slack me-3">
                          <span className="fab fa-slack-hash"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank" aria-label="dribbble social link" className="icon-dribbble me-3">
                          <span className="fab fa-dribbble"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                <div className="card border-0 p-2 p-md-3 p-lg-5">
                  <div className="card-header bg-white border-0 text-center">
                    <h2>Want to work with us?</h2>
                    <p>Cool! Let’s talk about your project</p>
                  </div>
                  <div className="card-body pt-0">
                    <form action="#">
                      <div className="mb-4">
                        <label htmlFor="name">Your Name</label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3"><span className="fa fa-user-circle"></span></span>
                          <input type="text" className="form-control" placeholder="e.g. Bonnie Green" id="name" required />
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="email">Your Email</label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon4"><span className="fa fa-envelope"></span></span>
                          <input type="email" className="form-control" placeholder="example@company.com" id="email" required />
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="message">Your Message</label>
                        <textarea placeholder="Your message" className="form-control" id="message" rows="4" required></textarea>
                      </div>
                      <div className="d-grid">
                        <button type="submit" className="btn btn-secondary">Send message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </main>
      {/* <section className="section py-0">
        <div className="container z-2">
          <div className="row position-relative justify-content-center align-items-cente">
            <div className="col-12">
              <div className="card border-light px-4 py-1">
                <div className="card-body text-center text-md-left">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h2 className="h1 mb-3">Torne-se um de nós</h2>
                      <p className="lead mb-4">
                        Quer fazer parte da nossa equipe e trabalhar remotamente de qualquer lugar que desejar?
                        Mal podemos esperar para ouvir de você!
                      </p>
                      <a href="#" className="btn btn-primary">
                        <span className="me-1">
                          <span className="fa fa-file"></span>
                        </span>
                        Verifique carreiras
                      </a>
                    </div>
                    <div className="col-12 col-md-6 mt-5 mt-md-0 text-md-right">
                      <img src="/img/empresa.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <footer className="footer pb-6 bg-primary text-white pt-9 mt-n9">
        <div className="container">
          <div className="row mt-6">
            <div className="col-md-4">
              <p><strong>Pixel</strong> is a large User Interface Kit that will help you prototype and design beautiful, creative and modern websites.</p>
              <ul className="d-flex flex-nowrap gap-4 social-buttons mb-5 mb-lg-0">
                <li>
                  <a href="https://twitter.com/themesberg" aria-label="twitter social link" className="icon icon-xs icon-white"
                    data-toggle="tooltip" data-placement="top" title="Follow us on Twitter">
                    <span className="fa fa-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/Themesberg-214738555737136/"
                    className="icon icon-xs icon-white" aria-label="facebook social link" data-toggle="tooltip"
                    data-placement="top" title="Like us on Facebook">
                    <span className="fa fa-facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="https://github.com/themesberg" aria-label="github social link" className="icon icon-xs icon-white"
                    data-toggle="tooltip" data-placement="top" title="Star us on Github">
                    <span className="fa fa-github"></span>
                  </a>
                </li>
                <li>
                  <a href="https://dribbble.com/themesberg"
                    className="icon icon-xs icon-white" aria-label="dribbble social link" data-toggle="tooltip"
                    data-placement="top" title="Like us on Dribbble">
                    <span className="fa fa-dribbble"></span>
                  </a>
                </li>
              </ul>
              <a href="#" target="_blank">
                <img className="mt-4" src="/img/playstore.png" height={40} />
              </a><br />
              <a href="#" target="_blank">
                <img className="mt-2" src="/img/appstore.png" height={40} />
              </a>
            </div>

            <div className="col-6 col-md-2 mb-5 mb-lg-0">
              <h3 className="h5">Themesberg</h3>
              <ul className="links-vertical">
                <li><a target="_blank" href="https://themesberg.com/blog">Blog</a></li>
                <li><a target="_blank" href="https://themesberg.com/themes">Themes</a></li>
                <li><a target="_blank" href="https://themesberg.com/about">About Us</a>
                </li>
                <li><a target="_blank" href="https://themesberg.com/contact">Contact Us</a></li>
              </ul>
            </div>
            <div className="col-6 col-md-2 mb-5 mb-lg-0">
              <h3 className="h5">Other</h3>
              <ul className="links-vertical">
                <li><a href="https://themesberg.com/docs/bootstrap-5/pixel/getting-started/quick-start/" target="_blank">Docs
                  <span className="badge badge-sm badge-secondary me-2">v3.0</span></a></li>
                <li><a href="https://themesberg.com/docs/pixel-bootstrap/getting-started/changelog" target="_blank">Changelog</a></li>
                <li><a target="_blank" href="https://themesberg.com/licensing">License</a>
                </li>
                <li><a target="_blank" href="https://github.com/themesberg/pixel-bootstrap-ui-kit/issues">Support</a></li>
              </ul>
            </div>
            <div className="col-12 col-md-4 mb-5 mb-lg-0">
              <span className="h5">Subscribe</span>
              <p className="text-muted font-small mt-2">Join our mailing list. We write rarely, but only the best content.
              </p>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <label className="h6 fw-normal text-muted d-none" htmlFor="exampleInputEmail3">Email address</label>
                    <input type="email" className="form-control mb-2" placeholder="example@company.com" name="email"
                      aria-label="Subscribe form" id="exampleInputEmail3" required />
                  </div>
                  <div className="col-12 d-grid">
                    <button type="submit" className="btn btn-tertiary" data-loading-text="Sending">
                      <span>Subscribe</span>
                    </button>
                  </div>
                </div>
              </form>
              <p className="text-muted font-small m-0">We’ll never share your details. See our <a className="text-white"
                href="#">Privacy Policy</a></p>
            </div>
          </div>
          <hr className="my-5" />
          <div className="row">
            <div className="col mb-md-0">
              <a href="https://themesberg.com" target="_blank" className="d-flex justify-content-center">
                <img src="/img/themesberg.svg" height="25" className="mb-3" alt="Themesberg Logo" />
              </a>
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © Themesberg
                  <span className="current-year">2020</span>. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default HomePage;
